<template>
  <el-card>
    <div class="flex-between mb20">
      <h3>法律：{{ titleInfo.lawCode }}</h3>
      <h3>章节：{{ titleInfo.lawDetailsName }}</h3>
      <h3>解读点：{{ titleInfo.unscramblePoint }}</h3>
      <el-button
        size="small"
        @click="back"
      >
        返 回
      </el-button>
    </div>
    <div class="filter-wrap flex-between">
      <div class="flex-start search-filter-wrap">
        <el-input
          v-model="listQuery.searchStr"
          width="180"
          placeholder="请输入内容"
          class="mr10"
          clearable
          @clear="handleFilter"
        />
        <el-button
          size="small"
          type="primary"
          icon="el-icon-search"
          @click="handleFilter"
        >
          搜索
        </el-button>
      </div>
      <div class="text-right">
        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="openDialog(1)"
        >
          新增
        </el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      border
      fit
      highlight-current-row
      :data="tableData"
      stripe
      style="width: 100%"
    >
      <el-table-column
        label="编号"
        :show-overflow-tooltip="true"
        prop="code"
      />
      <el-table-column
        label="最佳实践"
        :show-overflow-tooltip="true"
        prop="content"
      />
      <el-table-column
        label="创建人"
        :show-overflow-tooltip="true"
        prop="createName"
      />
      <el-table-column
        label="发生时间"
        prop="createTime"
      >
        <template slot-scope="{row}">
          {{ row.createTime ? formatDateStr(row.createTime) : '' }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="120px"
      >
        <template slot-scope="{row}">
          <el-link
            class="mr10"
            type="primary"
            @click="openDialog(2,row)"
          >
            <el-tooltip
              content="编辑"
              placement="top"
              effect="light"
            >
              <i class="el-icon-edit" />
            </el-tooltip>
          </el-link>
          <el-link
            :underline="false"
            type="primary"
            @click="delLaw(row)"
          >
            <el-tooltip
              content="删除"
              placement="top"
              effect="light"
            >
              <i class="el-icon-delete" />
            </el-tooltip>
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.pageSize"
      @pagination="getList"
    />
    <BestPracticeSave
      ref="lawsSave"
      :c-type="2"
      @handleFilter="handleFilter"
    />
  </el-card>
</template>

<script>
import pagination from '@core/components/pagination/Pagination.vue'
import { error, success } from '@core/utils/utils'
import { BestPracticePage, GetLawUnscrambleById,DeleteUnscrambleBasePractice } from '@/api/knowledgeBase/laws'
import BestPracticeSave from '@/views/knowledge/bestPractice/BestPracticeSave.vue'

export default {
  components: {
    pagination,
    BestPracticeSave,
  },

  data() {
    return {
      loading: false,
      total: 0,
      listQuery: {
        page: 1,
        pageSize: 10,
        searchStr: '',
        lawUnscrambleId: Number(this.$route.query.uId),
      },
      tableData: [],
      //  1征集意见 2正式使用 停用
      statusObj: {
        1: '征集意见',
        2: '正式使用',
        3: '停用',
      },
      titleInfo: {},
    }
  },
  created() {
    this.getDetailById()
    this.getList()
  },
  mounted() {
    this.navActiveInit(1, '法律解读', '知识库管理', '/lawUnscramble')
  },
  destroyed() {
    this.navActiveInit(2, '法律解读', '知识库管理', '/lawUnscramble')
  },
  methods: {
    getDetailById() {
      GetLawUnscrambleById({ id: this.listQuery.lawUnscrambleId }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.titleInfo = { ...resData.data }
        }
      })
    },
    back() {
      this.$router.push({
        path: '/lawUnscramble',
      })
    },
    openDialog(saveType, row) {
      this.$refs.lawsSave.dialogVisible = true
      this.$refs.lawsSave.form.lawCode = this.titleInfo.lawCode
      if (saveType === 2) {
        this.$refs.lawsSave.form.code = row.code
        this.$refs.lawsSave.form.lawUnscrambleIdList = row.lawUnscrambleIdList
      }else{
        this.$refs.lawsSave.form.lawUnscrambleIdList = [this.listQuery.lawUnscrambleId]
      }
    },
    delLaw(row) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const submitData = {
          basePracticeCode: row.code,
          lawUnscrambleId: this.listQuery.lawUnscrambleId,
        }
        DeleteUnscrambleBasePractice(submitData).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.handleFilter()
          } else {
            error(resData.msg)
          }
        })
      })
    },
    getList() {
      this.loading = true
      BestPracticePage(this.listQuery).then(res => {
        this.loading = false
        this.tableData = res.data.data.list
        this.total = Number(res.data.data.total)
      }).catch(() => {
        this.loading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
  },
}
</script>
